import { z } from 'zod';

const todoSchema = z
    .object({
    id: z.string(),
    title: z
        .string()
        .min(1, {
        message: "Must be at least 1 character",
    })
        .max(100, { message: "Must be maximum 100 characters" })
        .refine((val) => val.trim().length !== 0, {
        message: "Must not be empty",
    }),
    completed: z.boolean(),
    createdAt: z.date().or(z.string()),
})
    .strip();
const todoCreateSchema = todoSchema
    .pick({
    title: true,
})
    .extend({
    completed: z.boolean().optional(),
})
    .strip();
const todoUpdateSchema = todoCreateSchema
    .partial()
    .extend({
    id: z.string(),
})
    .strip();
const todosFilterSchema = z.object({
    completed: z.boolean().optional(),
});

const userSchema = z
    .object({
    id: z.string(),
    username: z
        .string()
        .min(3, { message: "Username must be at least 3 characters" })
        .refine((value) => value.trim().length > 0, {
        message: "You can't just use spaces for a username 🙃",
    }),
    email: z.string().min(1, { message: "Email is required" }).email({
        message: "Must be a valid email",
    }),
})
    .strip();
const userLoginSchema = userSchema
    .pick({ email: true })
    .extend({
    password: z
        .string()
        .min(6, { message: "Password must be at least 6 characters" })
        .refine((value) => value.trim().length > 0, {
        message: "You can't just use spaces for a password 🙃",
    }),
})
    .strip();
const userSignupSchema = userSchema
    .pick({ username: true })
    .merge(userLoginSchema)
    .extend({
    confirmPassword: z
        .string()
        .min(6, { message: "Password must be at least 6 characters" })
        .refine((value) => value.trim().length > 0, {
        message: "You can't just use spaces for a password 🙃",
    }),
})
    .strip()
    .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
});

export { todoCreateSchema, todoSchema, todoUpdateSchema, todosFilterSchema, userLoginSchema, userSchema, userSignupSchema };
